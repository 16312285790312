import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type {
  Invoice,
  PaymentMethod,
  Plan,
  SubscriptionGroup,
} from "@trainwell/types";
import { differenceInMonths } from "date-fns";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/stores/store";
import { refetchClient } from "./clientSlice";

export const fetchBillingDetails = createAsyncThunk(
  "billing/fetchBillingDetails",
  async (userId: string) => {
    console.log(`Fetching billing details for: ${userId}`);

    const [paymentMethods, plans, invoices, balance, subscriptionGroup] =
      await Promise.all([
        api.paymentMethods.findMany(userId),
        api.subscriptions.findMany(userId),
        api.invoices.findMany(userId),
        api.clients.getBalance(userId),
        api.subscriptionGroups.getForClient(userId),
      ]);

    return {
      paymentMethods: paymentMethods,
      plans: plans,
      invoices: invoices,
      balance: balance,
      subscriptionGroup: subscriptionGroup,
    };
  },
);

export const refreshPaymentMethods = createAsyncThunk(
  "billing/refreshPaymentMethods",
  async (_, { getState }) => {
    console.log(`Refresh payment methods`);

    const state = getState() as RootState;

    const client = state.client.client;

    if (!client?.user_id) {
      throw new Error("Client not found");
    }

    const paymentMethods = await api.paymentMethods.findMany(client.user_id);

    return {
      paymentMethods: paymentMethods,
    };
  },
);

export const switchSubscription = createAsyncThunk(
  "billing/switchSubscription",
  async (stripe_price_id: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (
      !state.client.client ||
      !state.client.client.account.membership.stripe_customer_id
    ) {
      throw new Error("Client not found");
    }

    const response = await api.clients.switchSubscriptions(
      state.client.client.user_id,
      {
        stripe_price_id,
      },
    );

    return response;
  },
);

export const cancelSubscriptionSwitch = createAsyncThunk(
  "billing/cancelSubSwitch",
  async (planId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const response = await api.clients.cancelSubscriptionSwitch(
      state.client.client.user_id,
      { plan_id: planId },
    );

    if (response.status === "succeeded") {
      dispatch(resetBilling());
      dispatch(fetchBillingDetails(state.client.client.user_id));
    }

    return response;
  },
);

// Payment methods

export const removePaymentMethod = createAsyncThunk(
  "billing/removePaymentMethod",
  async (paymentMethodId: string) => {
    const response = await api.paymentMethods.removeOne(paymentMethodId);

    return response;
  },
);

export const retryCharge = createAsyncThunk(
  "billing/retryCharge",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (
      !state.client.client ||
      !state.client.client.account.membership.stripe_customer_id
    ) {
      throw new Error("Client not found");
    }

    const response = await api.clients.retryCharge(state.client.client.user_id);

    dispatch(fetchBillingDetails(state.client.client.user_id));

    return response;
  },
);

export const setDefaultPaymentMethod = createAsyncThunk(
  "billing/setDefaultPaymentMethod",
  async (paymentMethodId: string, { getState }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const response = await api.paymentMethods.makeDefault(paymentMethodId);

    return response;
  },
);

// Plans

export const renewPlan = createAsyncThunk(
  "billing/renewPlan",
  async (priceId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const plan = await api.clients.renewPlan({
      userId: state.client.client.user_id,
      priceId: priceId,
      couponId: state.billing.newCouponId,
    });

    dispatch(fetchBillingDetails(state.client.client.user_id));

    return plan;
  },
);

export const revokeCancelRequest = createAsyncThunk(
  "billing/revokeCancelRequest",
  async (planId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const plan = await api.plans.revokeCancelRequest(planId);

    dispatch(updateLocalPlan(plan));

    return plan;
  },
);

export const endPause = createAsyncThunk(
  "billing/endPause",
  async (planId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const plan = await api.plans.endPause(planId);

    dispatch(updateLocalPlan(plan));

    const newInvoices = await api.invoices.findMany(
      state.client.client.user_id,
    );

    return { plan: plan, invoices: newInvoices };
  },
);

export const revokePauseRequest = createAsyncThunk(
  "billing/revokePauseRequest",
  async (planId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const plan = await api.plans.revokePauseRequest(planId);

    dispatch(updateLocalPlan(plan));

    dispatch(refetchClient());

    return plan;
  },
);

export const pausePlan = createAsyncThunk(
  "billing/pausePlan",
  async (
    data: { planId: string; reason: string; months: number },
    { getState, dispatch },
  ) => {
    const { planId, reason, months } = data;

    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const plan = await api.plans.pause(planId, reason, months);

    dispatch(updateLocalPlan(plan));

    dispatch(refetchClient());

    return plan;
  },
);

export const cancelPlan = createAsyncThunk(
  "billing/cancelPlan",
  async (
    data: {
      planId: string;
      reason: string;
      cancelOptions: string[];
      customCancelOption?: string;
      responseCancelOption: string;
    },
    { dispatch },
  ) => {
    const {
      planId,
      reason,
      cancelOptions,
      responseCancelOption,
      customCancelOption,
    } = data;

    const plan = await api.plans.cancel({
      planId: planId,
      reason: reason,
      cancelOptions: cancelOptions,
      customCancelOption: customCancelOption,
      responseCancelOption: responseCancelOption ?? undefined,
    });

    dispatch(updateLocalPlan(plan));

    return plan;
  },
);

// Define a type for the slice state
interface BillingState {
  plans: Plan[] | undefined;
  paymentMethods: PaymentMethod[] | undefined;
  invoices: Invoice[] | undefined;
  balance: number;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  retryChargeStatus: "idle" | "loading" | "succeeded" | "failed";
  switchSubStatus: "idle" | "loading" | "succeeded" | "failed";
  subscriptionGroup: SubscriptionGroup | undefined;
  newCouponId: string | undefined;
}

// Define the initial state using that type
const initialState: BillingState = {
  plans: undefined,
  paymentMethods: undefined,
  invoices: undefined,
  balance: 0,
  status: "idle",
  error: undefined,
  retryChargeStatus: "idle",
  switchSubStatus: "idle",
  subscriptionGroup: undefined,
  newCouponId: undefined,
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    resetBilling: () => initialState,
    updateLocalPlan: (state, action: PayloadAction<Plan>) => {
      const newPlan = action.payload;

      if (!state.plans) {
        return;
      }

      const index = state.plans.findIndex((plan) => plan.id === newPlan.id);

      if (index !== -1) {
        state.plans[index] = newPlan;
      }
    },
    resetRetryChargeStatus: (state) => {
      state.retryChargeStatus = "idle";
    },
    resetswitchSubStatus: (state) => {
      state.switchSubStatus = "idle";
    },
    setCouponId: (state, action: PayloadAction<string>) => {
      state.newCouponId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBillingDetails.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchBillingDetails.fulfilled, (state, action) => {
      console.log("Fetched billing details");
      state.status = "succeeded";

      const { plans, paymentMethods, invoices, balance, subscriptionGroup } =
        action.payload;

      state.plans = plans;
      state.paymentMethods = paymentMethods;
      state.invoices = invoices;
      state.balance = balance.balance;
      state.subscriptionGroup = subscriptionGroup;
    });
    builder.addCase(fetchBillingDetails.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(refreshPaymentMethods.fulfilled, (state, action) => {
      console.log("Refreshed payment methods");
      const { paymentMethods } = action.payload;

      state.paymentMethods = paymentMethods;
    });
    builder.addCase(removePaymentMethod.fulfilled, (state, action) => {
      console.log("Removed payment method");
      const paymentMethodId = action.meta.arg;

      if (!state.paymentMethods) {
        return;
      }

      const index = state.paymentMethods.findIndex(
        (paymentMethod) => paymentMethod.id === paymentMethodId,
      );

      if (index !== -1) {
        state.paymentMethods.splice(index, 1);
      }
    });
    builder.addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
      console.log("Removed payment method");
      const paymentMethodId = action.meta.arg;

      if (!state.paymentMethods) {
        return;
      }

      for (const paymentMethod of state.paymentMethods) {
        paymentMethod.is_default = false;
      }

      const index = state.paymentMethods.findIndex(
        (paymentMethod) => paymentMethod.id === paymentMethodId,
      );

      if (index !== -1) {
        state.paymentMethods[index].is_default = true;
      }
    });
    builder.addCase(renewPlan.fulfilled, (state, action) => {
      console.log("Renewed plan");
      const newPlan = action.payload;

      if (!state.plans) {
        state.plans = [];
      }

      state.plans.unshift(newPlan);
    });
    builder.addCase(endPause.fulfilled, (state, action) => {
      const { invoices, plan } = action.payload;

      state.invoices = invoices;
    });
    builder.addCase(retryCharge.fulfilled, (state, action) => {
      const response = action.payload;

      state.retryChargeStatus = response.status;
    });
    builder.addCase(retryCharge.pending, (state) => {
      state.retryChargeStatus = "loading";
    });
    builder.addCase(retryCharge.rejected, (state, action) => {
      state.retryChargeStatus = "failed";
    });
    builder.addCase(switchSubscription.fulfilled, (state, action) => {
      state.switchSubStatus = "succeeded";
    });
    builder.addCase(switchSubscription.pending, (state) => {
      state.switchSubStatus = "loading";
    });
    builder.addCase(switchSubscription.rejected, (state, action) => {
      state.switchSubStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetBilling,
  updateLocalPlan,
  resetRetryChargeStatus,
  resetswitchSubStatus,
  setCouponId,
} = billingSlice.actions;

export default billingSlice.reducer;

export const selectRetryChargeStatus = (state: RootState) =>
  state.billing.retryChargeStatus;

export const selectSubscriptionOptions = (state: RootState) => {
  const client = state.client.client;

  if (!client) {
    return [];
  }

  const now = new Date();

  const clientTenureMonths = differenceInMonths(
    now,
    client.account.membership.date_membership_started ?? now,
  );

  return (state.billing.subscriptionGroup?.options ?? []).filter(
    (option) =>
      !option.required_tenure_months ||
      clientTenureMonths > option.required_tenure_months,
  );
};

export const selectSwitchSubStatus = (state: RootState) =>
  state.billing.switchSubStatus;
