import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {
  Box,
  Button,
  CardActionArea,
  Container,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { WorkoutTimes } from "@trainwell/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPage from "src/components/misc/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import { api } from "src/lib/trainwellApi";
import { fetchClient } from "src/slices/clientSlice";
import { fetchTrainer } from "src/slices/trainerSlice";

type Experience = "none" | "1_year" | "5_years" | "5+_years";

export default function SurveyPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const client = useAppSelector((state) => state.client.client);
  const [availability, setAvailability] = useState<WorkoutTimes[]>([
    { time: null, has_time: false, anytime: null },
    { time: null, has_time: false, anytime: true },
    { time: null, has_time: false, anytime: null },
    { time: null, has_time: false, anytime: true },
    { time: null, has_time: false, anytime: null },
    { time: null, has_time: false, anytime: true },
    { time: null, has_time: false, anytime: null },
  ]);
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPage, setCurrentPage] = useState(0);
  const { width } = useWindowDimensions();
  const trainerStatus = useAppSelector((state) => state.trainer.status);
  const trainer = useAppSelector((state) => state.trainer.trainer);

  const [p1q1, setP1q1] = useState("");
  const [p1q2, setP1q2] = useState("");
  const [p1q3, setP1q3] = useState("");

  const [experienceToggle, setExperienceToggle] = useState<
    Experience | undefined
  >(undefined);
  const [p1q4, setP1q4] = useState("");
  const [p1q5, setP1q5] = useState("");

  const [p2q1, setP2q1] = useState("");
  const [p2q2, setP2q2] = useState("");
  const [p2q3, setP2q3] = useState("");
  const [p2q4, setP2q4] = useState("");
  const [injuriesToggle, setInjuriesToggle] = useState<
    undefined | "yes" | "no"
  >();
  const [p2q5, setP2q5] = useState("");

  const [equipmentNotes, setEquipmentNotes] = useState("");
  const [gymNotes, setGymNotes] = useState("");
  const [equipmentToggle, setEquipmentToggle] = useState<
    "full_gym" | "home_gym" | "none" | undefined
  >(undefined);

  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const foundClientID = client?.user_id || urlParams.get("user_id");
  const foundTrainerId = urlParams.get("trainer_id") ?? client?.trainer_id;

  useEffect(() => {
    if (foundTrainerId && trainerStatus === "idle") {
      dispatch(fetchTrainer(foundTrainerId));
    }
  }, [foundTrainerId, trainerStatus]);

  useEffect(() => {
    if (!client && foundClientID) {
      dispatch(fetchClient(foundClientID));
    }
  }, [foundClientID]);

  if (!availability) {
    return null;
  }

  if (!trainer) {
    return <LoadingPage message="Loading trainer details" />;
  }

  const validPage1 =
    p1q1 &&
    p1q2 &&
    p1q3 &&
    experienceToggle &&
    (experienceToggle === "none" || (p1q4 && p1q5));

  const validPage2 =
    p2q1 &&
    p2q2 &&
    injuriesToggle &&
    (injuriesToggle === "no" || (p2q3 && p2q4)) &&
    p2q5 &&
    availability.some((day) => day.has_time) &&
    equipmentToggle &&
    (equipmentToggle === "none" || equipmentNotes) &&
    (equipmentToggle !== "full_gym" || gymNotes);

  return (
    <Container maxWidth="sm" sx={{ my: 4 }}>
      <Box
        maxWidth="xs"
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <IconButton
            size={isPhone ? "small" : undefined}
            aria-label="back"
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 0}
            sx={{
              opacity: currentPage === 0 ? 0 : undefined,
            }}
          >
            <ArrowBackIosNewRoundedIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Box sx={{ width: "100%", px: 1 }}>
          <LinearProgress
            variant="determinate"
            value={Math.min(currentPage / 3, 1) * 100}
          />
        </Box>
        <Box sx={{ flex: 1 }} />
      </Box>
      {currentPage === 0 && (
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
              mb: 4,
              "& b": { color: (theme) => theme.palette.primary.main },
            }}
          >
            Tell your trainer about yourself
          </Typography>
          <Typography
            sx={{
              mb: { xs: 2, sm: 3 },
              "& b": { color: (theme) => theme.palette.primary.main },
            }}
          >
            Your trainer will use this information to build a personalized
            workout plan. This should take about 5 minutes to complete.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 4,
              height: { xs: width - 74, sm: (width - 30) / 2, md: "300px" },
              width: "100%",
              position: "relative",
            }}
          >
            <img
              src={"/assets/pass-the-mic.svg"}
              width={"100%"}
              height={"100%"}
              alt=""
            />
          </Box>
        </Container>
      )}
      {currentPage === 1 && (
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: "bold",
              mb: 4,
              "& b": { color: (theme) => theme.palette.primary.main },
            }}
          >
            Meet Coach {trainer.first_name}!
          </Typography>
          <Typography
            sx={{
              mb: { xs: 2, sm: 3 },
              "& b": { color: (theme) => theme.palette.primary.main },
            }}
          >
            This video will introduce you to your trainer and give you an idea
            of what to expect from your trainwell experience
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 4,
              height: { xs: width - 74, sm: (width - 30) / 2, md: "300px" },
              width: "100%",
              position: "relative",
            }}
          >
            <video
              key={trainer.welcome_video?.video_url}
              controls
              width="100%"
              height="100%"
              style={{
                borderRadius: "10px",
                display: "block",
                objectFit: "contain",
              }}
              onPlay={() => {
                api.clients.watchedStockWelcomeVideo(client!.user_id);
              }}
            >
              <source src={trainer.welcome_video?.video_url} />
            </video>
          </Box>
        </Container>
      )}
      {currentPage === 2 && (
        <>
          <Typography variant="h1" sx={{ mb: 2 }}>
            Let&apos;s Get to Know You
          </Typography>
          <Stack spacing={4}>
            <Box>
              <Typography sx={{ mb: 2 }}>
                What keeps you busy? (work, family, hobbies, etc)
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                minRows={2}
                value={p1q1}
                onChange={(event) => {
                  setP1q1(event.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                What are your health and fitness goals and why are these
                important to you?
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                minRows={2}
                value={p1q2}
                onChange={(event) => {
                  setP1q2(event.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                In the past, what has prevented you from achieving these?
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                minRows={2}
                value={p1q3}
                onChange={(event) => {
                  setP1q3(event.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                How much experience do you have with exercise?
              </Typography>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <Select
                  placeholder="Select one"
                  value={experienceToggle}
                  onChange={(event) => {
                    setExperienceToggle(event.target.value as Experience);
                  }}
                >
                  <MenuItem value={"none" as Experience}>
                    Little to none
                  </MenuItem>
                  <MenuItem value={"1_year" as Experience}>
                    Less than one year
                  </MenuItem>
                  <MenuItem value={"5_years" as Experience}>1-5 Years</MenuItem>
                  <MenuItem value={"5+_years" as Experience}>5+ Years</MenuItem>
                </Select>
              </FormControl>
              {experienceToggle && experienceToggle !== "none" && (
                <>
                  <Typography sx={{ mb: 2 }}>
                    What have you historically done for exercise?
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={2}
                    value={p1q4}
                    onChange={(event) => {
                      setP1q4(event.target.value);
                    }}
                    sx={{ mb: 4 }}
                  />
                  <Typography sx={{ mb: 2 }}>
                    If you currently have a routine, what does that look like?
                    This will help us ensure your new program matches your
                    current abilities.
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={2}
                    value={p1q5}
                    onChange={(event) => {
                      setP1q5(event.target.value);
                    }}
                  />
                </>
              )}
              {experienceToggle === "none" && (
                <Typography>
                  That&apos;s great, we&apos;re honored to be your guide!
                </Typography>
              )}
            </Box>
          </Stack>
        </>
      )}
      {currentPage === 3 && (
        <>
          <Typography variant="h1" sx={{ mb: 2 }}>
            What Should Your Workouts Look Like?
          </Typography>
          <Stack spacing={4}>
            <Box>
              <Typography sx={{ mb: 2 }}>
                Which days of the week would you like to workout? We recommend
                starting with two or three, it&apos;s easy to add more later!
              </Typography>
              <Grid
                container
                columns={7}
                spacing={1}
                sx={{
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                {["Su", "M", "T", "W", "Th", "F", "S"].map((day, i) => (
                  <Grid item xs={1} key={day}>
                    <Paper
                      sx={{
                        height: "38px",
                        width: "38px",
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        textAlign: "center",
                        backgroundColor: (theme) =>
                          availability[i].has_time === true
                            ? theme.palette.primary.main
                            : undefined,
                        color: (theme) =>
                          availability[i].has_time === true
                            ? "white"
                            : theme.palette.text.secondary,
                      }}
                    >
                      <CardActionArea
                        sx={{ height: "100%", width: "100%" }}
                        onClick={(e) => {
                          const newAvailables = JSON.parse(
                            JSON.stringify(availability),
                          ) as WorkoutTimes[];

                          if (newAvailables[i].has_time === true) {
                            newAvailables[i].time = null;
                            newAvailables[i].anytime = null;
                          } else {
                            newAvailables[i].anytime = true;
                          }

                          newAvailables[i].has_time =
                            !newAvailables[i].has_time;

                          setAvailability(newAvailables);
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {day}
                        </Typography>
                      </CardActionArea>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              {availability.filter((day) => day.has_time).length > 4 && (
                <Typography color="error" sx={{ mt: 2 }} variant="body2">
                  These are days you want a trainwell workout. Clients have the
                  most success if they start with no more than 4. Your trainer
                  can always add more!
                </Typography>
              )}
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                How long would you like your workouts to be?
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                placeholder="30 mins"
                value={p2q1}
                onChange={(event) => {
                  setP2q1(event.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                Do you know what time of day you&apos;d like to workout? Would
                you like to receive app notifications on days you have workouts?
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                placeholder="3pm EST, no alerts"
                value={p2q2}
                onChange={(event) => {
                  setP2q2(event.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                Do you have any injuries and/or exercise limitations?
              </Typography>
              <FormControl fullWidth>
                <Select
                  placeholder="Yes or no"
                  value={injuriesToggle}
                  onChange={(event) => {
                    setInjuriesToggle(event.target.value as any);
                  }}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </FormControl>
              {injuriesToggle === "yes" && (
                <>
                  <Typography sx={{ mb: 2, mt: 2 }}>
                    What are these limitations? Be as detailed as possible!
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={2}
                    placeholder="Shoulder injury and asthma"
                    value={p2q3}
                    onChange={(event) => {
                      setP2q3(event.target.value);
                    }}
                    sx={{ mb: 2 }}
                  />
                  <Typography sx={{ mb: 2 }}>
                    What kind of movements aggravate these limitations? Be as
                    detailed as possible!
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={2}
                    placeholder="Anything with pressing my hands in front of me such as push-ups"
                    value={p2q4}
                    onChange={(event) => {
                      setP2q4(event.target.value);
                    }}
                  />
                </>
              )}
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                What equipment do you have?
              </Typography>
              <FormControl fullWidth>
                <Select
                  placeholder="Select one"
                  value={equipmentToggle}
                  onChange={(event) => {
                    setEquipmentNotes("");
                    setEquipmentToggle(event.target.value as any);
                  }}
                >
                  <MenuItem value={"full_gym"}>Full gym</MenuItem>
                  <MenuItem value={"home_gym"}>Home gym</MenuItem>
                  <MenuItem value={"none"}>None</MenuItem>
                </Select>
              </FormControl>
              {equipmentToggle === "home_gym" ? (
                <>
                  <Typography sx={{ mb: 2, mt: 4 }}>
                    Great! Which equipment do you have? Please list weights
                    where possible. You can also send a picture to your trainer
                    if you don&apos;t know what something is called!
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Dumbbells: 2 x 10 lbs, 2 x 15 lbs, 25 lbs"
                    multiline
                    minRows={2}
                    value={equipmentNotes}
                    onChange={(event) => {
                      setEquipmentNotes(event.target.value);
                    }}
                  />
                </>
              ) : equipmentToggle === "full_gym" ? (
                <>
                  <Typography sx={{ mb: 2, mt: 4 }}>Which gym?</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Planet Fitness, Anytime fitness, LA Fitness, etc"
                    multiline
                    minRows={2}
                    value={gymNotes}
                    onChange={(event) => {
                      setGymNotes(event.target.value);
                    }}
                  />
                  {gymNotes && (
                    <>
                      <Typography sx={{ mb: 2, mt: 2 }}>
                        Which pieces of equipment are you most comfortable with?
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="e.g. Barbells, Dumbbells, Smith Machine..."
                        multiline
                        minRows={2}
                        value={equipmentNotes}
                        onChange={(event) => {
                          setEquipmentNotes(event.target.value);
                        }}
                      />
                    </>
                  )}
                </>
              ) : equipmentToggle === "none" ? (
                <Typography>
                  No worries! We have hundreds of bodyweight exercises for any
                  goal 😊
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            <Box>
              <Typography sx={{ mb: 2 }}>
                When would you like to start?
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                placeholder="Tomorrow!"
                value={p2q5}
                onChange={(event) => {
                  setP2q5(event.target.value);
                }}
              />
            </Box>
          </Stack>
        </>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        {currentPage === 3 ? (
          <Button
            onClick={() => {
              navigate("/download-app");

              const formattedEquipmentNotes = `Equipment: ${
                equipmentToggle === "full_gym"
                  ? "full gym"
                  : equipmentToggle === "home_gym"
                    ? "home gym"
                    : "none"
              }${equipmentToggle === "full_gym" ? `\nGym: ${gymNotes}` : ""}${
                equipmentToggle === "full_gym" || equipmentToggle === "home_gym"
                  ? `\nPreferred equipment${equipmentNotes}`
                  : ""
              }`;

              const formattedInjuriesNotes = `Injuries: ${
                injuriesToggle === "yes" ? "Yes" : "none"
              }${
                injuriesToggle === "yes"
                  ? `\nInjuries and limitations:\n${p2q3}\n\nMovements to avoid:\n${p2q4}`
                  : ""
              }`;

              api.clients.submitSurvey({
                userId: client!.user_id,
                workoutTimes: availability,
                programmingNotes: `How long would you like your workouts to be?\n${p2q1}\n\nDo you know what time of day you'd like to workout? Would you like to receive app notifications on days you have workouts?\n${p2q2}\n\n${formattedInjuriesNotes}\n\n${formattedEquipmentNotes}`,
                detailedNotes: `When would you like to start?\n${p2q5}`,
                exerciseHistory: `Exercise experience: ${
                  experienceToggle === "none"
                    ? "little to none"
                    : experienceToggle === "1_year"
                      ? "< 1 year"
                      : experienceToggle === "5_years"
                        ? "1-5 years"
                        : "5+ years"
                }${
                  experienceToggle !== "none"
                    ? `\n\nWhat have you historically done for exercise?\n${p1q4}\n\nRoutine\n${p1q5}`
                    : ""
                }`,
                personalNotes: `What keeps you busy?\n${p1q1}`,
                outcomeNotes: `What are your health and fitness goals and why are these important to you?\n${p1q2}\n\nIn the past, what has prevented you from achieving these?\n${p1q3}`,
                quickNotes: `How long would you like your workouts to be?\n${p2q1}\n\nDo you know what time of day you'd like to workout? Would you like to receive app notifications on days you have workouts?\n${p2q2}\n\n${formattedInjuriesNotes}\n\n${formattedEquipmentNotes}`,
              });
            }}
            fullWidth
            disabled={
              Boolean(client?.account.dashboard.date_onboarded) ||
              !validPage1 ||
              !validPage2
            }
          >
            {client?.account.dashboard.date_onboarded
              ? "Already submitted"
              : "Submit survey"}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setCurrentPage(currentPage + 1);
              window.scrollTo(0, 0);
            }}
            fullWidth
            disabled={currentPage === 2 && !validPage1}
          >
            Continue
          </Button>
        )}
      </Box>
    </Container>
  );
}
