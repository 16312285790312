import LockIcon from "@mui/icons-material/Lock";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  alpha,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type { SubscriptionGroupOption } from "@trainwell/types";
import { useAddPaymentMethod } from "@trainwell/ui";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import {
  resetPaymentAttempt,
  setSelectedSubOption,
  subscribeFreemium,
} from "src/slices/paymentSlice";
import CTAButton from "./CTAButton";

type Props = {
  subOptions: SubscriptionGroupOption[];
  trainerId: string;
};

export default function Pay({ subOptions, trainerId }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const submitPaymentStatus = useAppSelector(
    (state) => state.payment.submitPaymentStatus,
  );
  const selectedPlan = useAppSelector(
    (state) => state.payment.selectedSubOption,
  );
  const isOverrideSubs = useAppSelector(
    (state) => state.payment.overrideSubscriptionOptions,
  )
    ? true
    : false;
  const isNoCard = false;
  const isFree = false;
  const brand = useAppSelector(
    (state) => state.client.client?.account.plan.brand,
  );
  const referralCampaign = null;

  const {
    addPaymentMethod,
    paymentErrorMessage,
    validationMessage,
    submitting: submittingPaymentMethod,
  } = useAddPaymentMethod({
    api: api,
    userId: client?.user_id ?? "",
    onSuccess: () => {
      dispatch(
        subscribeFreemium({
          trainerId: trainerId,
        }),
      );
    },
  });

  const stripe = useStripe();
  const elements = useElements();

  const [gymLocation, setGymLocation] = useState<string>();

  const isSRO50 = false;
  const isSRO60 = false;
  const isSROTest = isSRO60 || isSRO50;
  const srMultiplier = isSRO60 ? 0.4 : isSRO50 ? 0.5 : 1;

  const monthOff = false;

  const threeMonthsOff = false;

  let baseMonthlyPrice = 0;

  let totalPrice = 0;

  if (!isOverrideSubs) {
    baseMonthlyPrice = subOptions.reduce((acc, option) => {
      return option.interval === "month" && option.interval_count === 1
        ? option.monthly_price
        : acc;
    }, 99);

    totalPrice =
      subOptions[selectedPlan].interval === "month" &&
      subOptions[selectedPlan].interval_count === 1
        ? subOptions[selectedPlan].monthly_price
        : subOptions[selectedPlan].interval === "month"
          ? subOptions[selectedPlan].monthly_price *
            subOptions[selectedPlan].interval_count
          : subOptions[selectedPlan].monthly_price * 12;
  }

  useEffect(() => {
    if (submitPaymentStatus === "succeeded") {
      navigate("/schedule");
    }
  }, [submitPaymentStatus, dispatch]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    addPaymentMethod(true);
  }

  if (!elements || !stripe) {
    return null;
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ pb: 4 }}>
        <Typography
          variant="h1"
          sx={{ mb: { xs: 2, sm: 4 }, textAlign: "center" }}
        >
          Complete Registration
        </Typography>
        <Box sx={{ mb: 4 }}></Box>
        {!isFree && !isNoCard && (
          <>
            <Typography sx={{ mb: 1, fontWeight: "bold" }}>
              Price After Trial
            </Typography>
            <Divider />
            {isNoCard ? (
              <Typography textAlign="center" sx={{ my: 4 }}>
                After your trial ends you can enter a card and pay $
                {subOptions.length >= 2 ? subOptions[1].monthly_price : "99"}
                /mo to continue using trainwell.
              </Typography>
            ) : (
              <>
                {subOptions.length > 0 &&
                  subOptions.map((subOption, i) => {
                    const { title, monthly_price, details } = subOption;

                    const applySROffer = isSROTest && title === "Monthly Plan";

                    const monthOffOffer = monthOff && title === "Monthly Plan";

                    const threeOffOffer =
                      threeMonthsOff &&
                      subOption.interval === "month" &&
                      subOption.interval_count === 3;

                    const firstMonthPrice =
                      monthOffOffer || threeOffOffer
                        ? 0
                        : applySROffer
                          ? Math.round(monthly_price * srMultiplier)
                          : false;

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          my: 1,
                        }}
                        key={i}
                      >
                        <Box display="flex" alignItems="center">
                          <Radio
                            checked={selectedPlan === i}
                            onChange={() => {
                              dispatch(setSelectedSubOption(i));
                            }}
                            value={i}
                          />
                          <Typography>{title}</Typography>
                        </Box>
                        <Box sx={{ textAlign: "end" }}>
                          <Typography
                            sx={{
                              opacity: applySROffer || monthOffOffer ? 0.5 : 1,
                              textDecoration:
                                applySROffer || monthOffOffer
                                  ? "line-through"
                                  : "none",
                            }}
                          >
                            ${`${monthly_price}/mo`}
                          </Typography>
                          <Typography>
                            {(monthOffOffer || threeOffOffer || applySROffer) &&
                              `$${firstMonthPrice} first month`}
                          </Typography>
                          <Typography variant="caption">
                            {applySROffer || monthOffOffer
                              ? `$${monthly_price}/mo after`
                              : threeOffOffer
                                ? `$${monthly_price * 3} after`
                                : details}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                <Typography variant="caption">
                  All prices in United States Dollars (USD)
                </Typography>
              </>
            )}
            {referralCampaign === "referral_v3.0" && !isOverrideSubs && (
              <>
                <Typography
                  sx={{
                    fontSize: [13, "!important"],
                    textAlign: "right",
                    opacity: 0.5,
                    fontStyle: "italic",
                    color: "text.secondary",
                  }}
                >
                  ${(Math.round((baseMonthlyPrice / 2) * 100) / 100).toFixed(2)}{" "}
                  referral discount applied!
                </Typography>
                <Typography sx={{ textAlign: "right" }}>
                  Due after free trial:{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                      opacity: 0.5,
                      fontStyle: "italic",
                      color: "text.secondary",
                    }}
                  >
                    ${totalPrice}
                  </span>{" "}
                  $
                  {(
                    Math.round((totalPrice - baseMonthlyPrice / 2) * 100) / 100
                  ).toFixed(2)}
                </Typography>
              </>
            )}
            <Divider sx={{ mb: 4 }} />
          </>
        )}
        <form onSubmit={handleSubmit}>
          <>
            {!isFree && !isNoCard && (
              <>
                <Box
                  sx={{
                    mb: 2,
                    mt: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mr: 2 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Credit Card
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                      You can cancel your membership at anytime from the
                      trainwell app.
                    </Typography>
                  </Box>
                  <Tooltip title={"Payment secured by Stripe"}>
                    <LockIcon />
                  </Tooltip>
                </Box>
                <PaymentElement
                  options={{
                    layout: "auto",
                    readOnly: submittingPaymentMethod,
                    defaultValues: {
                      billingDetails: {
                        email: client?.email,
                        phone: client?.phone_number,
                        name: client?.full_name,
                      },
                    },
                    terms: {
                      card: "never",
                      applePay: "never",
                      googlePay: "never",
                    },
                  }}
                />
                {paymentErrorMessage && (
                  <Card
                    sx={{
                      px: 2,
                      py: 0.5,
                      mt: 2,
                      backgroundColor: (theme) =>
                        alpha(theme.palette.error.main, 0.2),
                      borderColor: (theme) => theme.palette.error.main,
                      display: "flex",
                      alignItems: "center",
                    }}
                    variant="outlined"
                  >
                    <WarningRoundedIcon fontSize="inherit" color="error" />
                    <Typography sx={{ ml: 1 }}>
                      {paymentErrorMessage}
                    </Typography>
                  </Card>
                )}
                {validationMessage && (
                  <Card
                    sx={{
                      px: 2,
                      py: 0.5,
                      mt: 2,
                      backgroundColor: (theme) =>
                        alpha(theme.palette.error.main, 0.2),
                      borderColor: (theme) => theme.palette.error.main,
                      display: "flex",
                      alignItems: "center",
                    }}
                    variant="outlined"
                  >
                    <WarningRoundedIcon fontSize="inherit" color="error" />
                    <Typography sx={{ ml: 1 }}>{validationMessage}</Typography>
                  </Card>
                )}
              </>
            )}
          </>
          {brand === "af" && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Gym location
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gymLocation}
                label="Gym location"
                onChange={(event) => {
                  setGymLocation(event.target.value as string);
                }}
              >
                <MenuItem value={4466}>Robert, LA</MenuItem>
                <MenuItem value={234}>Excelsior Springs, MO </MenuItem>
                <MenuItem value={5041}>Hannibal, MO </MenuItem>
                <MenuItem value={1481}>Chillicothe, MO </MenuItem>
                <MenuItem value={2576}>Kirksville, MO </MenuItem>
                <MenuItem value={4410}>Sanford, NC</MenuItem>
                <MenuItem value={2364}>Guthrie, OK </MenuItem>
                <MenuItem value={2250}>Center, TX</MenuItem>
                <MenuItem value={2665}>Hallsville, TX</MenuItem>
                <MenuItem value={3328}>Sandy, UT</MenuItem>
                <MenuItem value={4426}>Midvale, UT</MenuItem>
                <MenuItem value={1705}>Bristol, VA</MenuItem>
              </Select>
            </FormControl>
          )}
          <CTAButton
            submit
            CTAText={!isFree ? "Start Free Trial" : "Submit"}
            loading={
              submitPaymentStatus === "loading" || submittingPaymentMethod
            }
          />
        </form>
        <Typography textAlign="center" sx={{ mb: 1 }}>
          By subscribing, you agree to our{" "}
          <Link href="https://www.trainwell.net/terms-and-conditions">
            Terms &amp; Conditions
          </Link>{" "}
          and consent to receive email and SMS messages.
        </Typography>
      </Container>
      <Dialog
        open={submitPaymentStatus === "failed"}
        onClose={() => {
          dispatch(resetPaymentAttempt());
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Something went wrong finalizing your account!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Check your payment details and try again. Contact
            support@trainwell.net if you continue to run into issues.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(resetPaymentAttempt());
            }}
          >
            Check payment details
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={submitPaymentStatus === "account-error"}
        onClose={() => {
          dispatch(resetPaymentAttempt());
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Something went wrong finalizing your account!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please contact our support team for help at support@trainwell.net or
            go through the survey again from an incognito browser.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(resetPaymentAttempt());
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={submitPaymentStatus === "succeeded-phone-exists"}
        onClose={() => {
          dispatch(resetPaymentAttempt());
        }}
        aria-labelledby="phone-dialog-title"
        aria-describedby="phone-dialog-description"
      >
        <DialogTitle id="phone-dialog-title">Phone number in use</DialogTitle>
        <DialogContent>
          <DialogContentText id="phone-dialog-description">
            This phone number is already in use. Try signing into the app or
            contact support@trainwell.net if you continue to run into issues.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(resetPaymentAttempt());
            }}
          >
            Check phone number
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
