import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import type { Plan } from "@trainwell/types";
import { ErrorDialog } from "@trainwell/ui";
import { format, fromUnixTime, isPast } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { formatCentsToDollars, getAvailablePauseDurations } from "src/lib/misc";
import { trackEvent } from "src/slices/analyticsSlice";
import {
  cancelSubscriptionSwitch,
  resetRetryChargeStatus,
  retryCharge,
  revokeCancelRequest,
  revokePauseRequest,
  selectRetryChargeStatus,
  selectSubscriptionOptions,
} from "src/slices/billingSlice";
import CancelDialog from "./CancelDialog";
import PauseDialog from "./PauseDialog";
import PauseEndDialog from "./PauseEndDialog";
import PaymentDialog from "./PaymentDialog";
import RenewDialog from "./RenewDialog";
import SubSwitchDialog from "./SubscriptionDialog";

const planStatus: Record<string, string> = {
  incomplete: "Incomplete",
  incomplete_expired: "Incomplete expired",
  trialing: "Trialing",
  active: "Active",
  past_due: "Past due",
  canceled: "Canceled",
  unpaid: "Unpaid",
};

type Props = {
  sx?: SxProps<Theme>;
};

export default function Plans({ sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const plans = useAppSelector((state) => state.billing.plans);
  const paymentMethods = useAppSelector(
    (state) => state.billing.paymentMethods,
  );
  const subscriptionOptions = useAppSelector(selectSubscriptionOptions);

  const [switchOptionsDialogOpen, setSwitchOptionsDialogOpen] = useState(false);
  const [displayedPlans, setDisplayedPlans] = useState<Plan[]>();
  const [pausePlanId, setPausePlanId] = useState<null | string>(null);
  const [pauseEndPlanId, setPauseEndPlanId] = useState<null | string>(null);
  const [cancelPlanId, setCancelPlanId] = useState<null | string>(null);
  const retryChargeStatus = useAppSelector(selectRetryChargeStatus);
  const [showOldPlans, setShowOldPlans] = useState(false);
  const [paymentMethodDialogOpen, setPaymentMethodDialogOpen] = useState(false);
  const [renewDialogOpen, setRenewDialogOpen] = useState(false);
  const [renewPlanId, setRenewPlanId] = useState<string | null>(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const activeTests = useAppSelector((state) => state.analytics.activeTests);
  const isPayToPauseC = activeTests.includes("pause_test_c");

  useEffect(() => {
    if (showOldPlans) {
      setDisplayedPlans(plans);
    } else {
      setDisplayedPlans(
        plans?.filter(
          (sub) =>
            sub.status !== "canceled" && sub.status !== "incomplete_expired",
        ),
      );
    }
  }, [plans, showOldPlans]);

  const showRenewPlanButton = plans?.every(
    (plan) =>
      plan.status === "canceled" || plan.status === "incomplete_expired",
  );

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Plans
      </Typography>
      {showRenewPlanButton && (
        <Button
          variant="contained"
          onClick={() => {
            if (!paymentMethods || paymentMethods.length === 0) {
              setPaymentMethodDialogOpen(true);
            } else {
              setRenewDialogOpen(true);
            }
          }}
          sx={{ mb: 2 }}
        >
          Renew plan
        </Button>
      )}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {displayedPlans?.map((plan, i) => {
          const name = plan.setToUpdate
            ? `Updates ${plan.nextInvoiceDate ? format(new Date(plan.nextInvoiceDate), "MMM d") : "--"}`
            : planStatus[plan.status];

          const isPreTrial = Boolean(
            plan.trialEnd && !client?.account.dashboard.date_onboarded,
          );
          const isInTrial = Boolean(
            !isPreTrial &&
              plan.trialEnd &&
              !isPast(fromUnixTime(plan.trialEnd)),
          );

          let intervalString = "per ";

          if (plan.interval_count > 1) {
            intervalString += `${plan.interval_count.toString()} `;
          }

          if (plan.interval === "year") {
            intervalString += "year";
          } else if (plan.interval === "week") {
            intervalString += "week";
          } else if (plan.interval === "day") {
            intervalString += "day";
          } else {
            intervalString += "month";
          }

          if (plan.interval_count > 1) {
            intervalString += "s";
          }

          let message = "";

          if (plan.willCancelOn) {
            if (!client?.account.dashboard.date_onboarded) {
              message = "Plan canceled.";
            } else {
              message = `Cancels on ${format(
                fromUnixTime(plan.willCancelOn),
                "MMMM do, yyyy",
              )}.`;
            }
          } else if (plan.canceledAt) {
            message = `Canceled on ${format(
              fromUnixTime(plan.canceledAt),
              "MMMM do, yyyy",
            )}.`;
          } else if (plan.willPauseOn) {
            message = `Pauses on ${format(
              fromUnixTime(plan.willPauseOn),
              "MMMM do, yyyy",
            )}.`;
          } else if (plan.pausedUntil) {
            message = `Paused until ${format(
              fromUnixTime(plan.pausedUntil),
              "MMMM d, yyyy",
            )}`;
          } else if (plan.setToUpdate) {
            // message = `Updates on ${format(
            //   fromUnixTime(plan.currentPeriodEnd),
            //   "MMMM d, yyyy",
            // )}`;
          } else if (isPreTrial) {
            message = `Your ${
              client?.account.plan.original_trial_length ?? 14
            } day trial begins when you call your trainer.`;
          } else if (isInTrial) {
            message = `Your ${
              client?.account.plan.original_trial_length ?? 14
            } day trial ends on ${format(
              fromUnixTime(plan.trialEnd!),
              "MMMM d, yyyy",
            )}.`;
          } else if (plan.status === "past_due") {
            message = `The most recent payment failed.`;
          } else {
            message = `Renews on ${format(
              fromUnixTime(plan.currentPeriodEnd),
              "MMMM do, yyyy",
            )}.`;
          }

          let couponMessage = "";

          if (plan.coupon) {
            if (plan.coupon.amount_off) {
              couponMessage += `${formatCentsToDollars(plan.coupon.amount_off)}`;
            } else if (plan.coupon.percent_off) {
              couponMessage += `${plan.coupon.percent_off}%`;
            }

            couponMessage += " off";

            if (plan.coupon.duration === "forever") {
              couponMessage += " forever";
            } else if (plan.coupon.duration === "once") {
              couponMessage += " once";
            } else if (plan.coupon.duration === "repeating") {
              couponMessage += ` for ${plan.coupon.duration_in_months} months`;
            }
          }

          const availablePauseDurations = getAvailablePauseDurations(plan);

          return (
            <Grid item xs={12} sm={6} key={i}>
              <Card sx={{ p: 2 }} variant="outlined">
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold" }}>
                      trainwell
                    </Typography>
                    {plan.price && (
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        {formatCentsToDollars(plan.price)} {intervalString}
                      </Typography>
                    )}
                    {couponMessage && (
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.text.secondary }}
                      >
                        {couponMessage}
                      </Typography>
                    )}
                  </Box>
                  <Stack spacing={1} direction="row">
                    {isPreTrial || isInTrial ? (
                      <Chip size="small" label={`Trial`} />
                    ) : plan.pausedUntil ? (
                      <Chip size="small" label={"Paused"} />
                    ) : (
                      <Chip
                        size="small"
                        color={
                          plan.setToUpdate
                            ? "info"
                            : plan.status === "active"
                              ? "success"
                              : plan.status === "past_due" ||
                                  plan.status === "unpaid"
                                ? "error"
                                : "default"
                        }
                        label={name}
                      />
                    )}
                    {plan.willCancelOn && (
                      <Chip
                        size="small"
                        label={`Cancels ${format(
                          fromUnixTime(plan.willCancelOn),
                          "MMM d",
                        )}`}
                      />
                    )}
                    {plan.willPauseOn && (
                      <Chip
                        size="small"
                        label={`Pauses ${format(
                          fromUnixTime(plan.willPauseOn),
                          "MMM d",
                        )}`}
                      />
                    )}
                  </Stack>
                </Box>
                {plan.nextInvoiceDate && !plan.willPauseOn && (
                  <Typography
                    sx={{ mb: 1 }}
                  >{`Next invoice ${client?.account.dashboard.date_onboarded ? `on ${format(new Date(plan.nextInvoiceDate), "MMM d, yyyy")} for ` : "after trial "}${plan.nextInvoiceAmount ? formatCentsToDollars(plan.nextInvoiceAmount) : "$0"}`}</Typography>
                )}
                <Typography sx={{ mb: 1 }}>{message}</Typography>
                <Stack direction={"row"} spacing={1} alignItems="center">
                  {plan.status === "past_due" &&
                    !plan.willCancelOn &&
                    !plan.willPauseOn &&
                    !plan.pausedUntil && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          dispatch(retryCharge());
                          dispatch(
                            trackEvent({
                              event_type: "retry_charge_button_click",
                              event_content: { location: "plan_card" },
                            }),
                          );
                        }}
                      >
                        Retry charge
                      </Button>
                    )}
                  {plan.willCancelOn &&
                    !(
                      plan.status === "canceled" ||
                      plan.status === "incomplete_expired"
                    ) && (
                      <LoadingButton
                        variant="contained"
                        onClick={() => {
                          if (!paymentMethods || paymentMethods.length === 0) {
                            setPaymentMethodDialogOpen(true);
                            setRenewPlanId(plan.id);
                          } else {
                            setButtonLoading(true);

                            dispatch(revokeCancelRequest(plan.id)).then(() => {
                              setButtonLoading(false);
                            });
                          }
                        }}
                        loading={buttonLoading}
                      >
                        Don&apos;t cancel
                      </LoadingButton>
                    )}
                  {plan.pausedUntil && (
                    <LoadingButton
                      variant="contained"
                      onClick={() => {
                        setPauseEndPlanId(plan.id);
                      }}
                      loading={buttonLoading}
                      startIcon={<PlayArrowRoundedIcon />}
                    >
                      Resume plan
                    </LoadingButton>
                  )}
                  {!(
                    plan.status === "canceled" ||
                    plan.status === "past_due" ||
                    plan.status === "incomplete" ||
                    plan.status === "incomplete_expired"
                  ) &&
                    client?.account.membership.state_pending === null &&
                    !plan.willCancelOn &&
                    !plan.willPauseOn &&
                    !plan.pausedUntil &&
                    !isPreTrial &&
                    !isInTrial &&
                    availablePauseDurations.length > 0 &&
                    !isPayToPauseC && (
                      <Button
                        size="small"
                        variant="text"
                        onClick={() => {
                          setPausePlanId(plan.id);
                          dispatch(
                            trackEvent({
                              event_type: "pause_button_click",
                              event_content: { location: "plan_card" },
                            }),
                          );
                        }}
                      >
                        Pause
                      </Button>
                    )}
                  {plan.willPauseOn && (
                    <LoadingButton
                      size="small"
                      variant="text"
                      onClick={() => {
                        setButtonLoading(true);

                        dispatch(revokePauseRequest(plan.id)).then(() => {
                          setButtonLoading(false);
                        });
                      }}
                      loading={buttonLoading}
                    >
                      Don&apos;t pause
                    </LoadingButton>
                  )}
                  {!(
                    plan.status === "canceled" ||
                    plan.status === "incomplete" ||
                    plan.status === "incomplete_expired"
                  ) &&
                    !plan.willCancelOn &&
                    !plan.willPauseOn && (
                      <Button
                        size="small"
                        variant="text"
                        onClick={() => {
                          setCancelPlanId(plan.id);
                          dispatch(
                            trackEvent({
                              event_type: "cancel_button_click",
                              event_content: { location: "plan_card" },
                            }),
                          );
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  {!(
                    plan.status === "canceled" ||
                    plan.status === "past_due" ||
                    plan.status === "incomplete" ||
                    plan.status === "incomplete_expired"
                  ) &&
                    !plan.setToUpdate &&
                    subscriptionOptions.filter(
                      (option) =>
                        option.stripe_price_id !== plan.stripe_price_id,
                    ).length > 0 && (
                      <Button
                        size="small"
                        variant="text"
                        onClick={() => {
                          setSwitchOptionsDialogOpen(true);

                          dispatch(
                            trackEvent({
                              event_type: "switch_button_click",
                              event_content: { location: "plan_card" },
                            }),
                          );
                        }}
                      >
                        Switch plan
                      </Button>
                    )}
                  {!(
                    plan.status === "canceled" ||
                    plan.status === "incomplete" ||
                    plan.status === "past_due" ||
                    plan.status === "incomplete_expired"
                  ) &&
                    plan.setToUpdate && (
                      <Button
                        size="small"
                        variant="text"
                        onClick={() => {
                          dispatch(cancelSubscriptionSwitch(plan.id));

                          dispatch(
                            trackEvent({
                              event_type: "cancel_switch_button_click",
                              event_content: { location: "plan_card" },
                            }),
                          );
                        }}
                      >
                        Cancel switch
                      </Button>
                    )}
                </Stack>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {plans && plans.length > 1 && (
        <Button
          variant="text"
          onClick={() => {
            setShowOldPlans(!showOldPlans);
          }}
          size="small"
          startIcon={
            showOldPlans ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />
          }
        >
          {showOldPlans ? "Hide old plans" : "Show old plans"}
        </Button>
      )}
      {pausePlanId && (
        <PauseDialog
          open={Boolean(pausePlanId)}
          onClose={() => {
            setPausePlanId(null);
          }}
          planId={pausePlanId}
        />
      )}
      {pauseEndPlanId && (
        <PauseEndDialog
          open={Boolean(pauseEndPlanId)}
          onClose={() => {
            setPauseEndPlanId(null);
          }}
          planId={pauseEndPlanId}
        />
      )}
      {cancelPlanId && (
        <CancelDialog
          open={Boolean(cancelPlanId)}
          onClose={() => {
            setCancelPlanId(null);
          }}
          planId={cancelPlanId}
        />
      )}
      {switchOptionsDialogOpen && (
        <SubSwitchDialog
          open={switchOptionsDialogOpen}
          subOptions={subscriptionOptions}
          onClose={() => {
            setSwitchOptionsDialogOpen(false);
          }}
        />
      )}

      {retryChargeStatus === "failed" && (
        <ErrorDialog
          title="Something went wrong"
          body="Please try again, and if the problem persists, contact support."
          buttonCTA="Try again"
          onClick={() => {
            dispatch(resetRetryChargeStatus());
          }}
        />
      )}
      <PaymentDialog
        open={paymentMethodDialogOpen}
        onClose={() => {
          setPaymentMethodDialogOpen(false);

          if (renewPlanId) {
            dispatch(revokeCancelRequest(renewPlanId));
            setRenewPlanId(null);
          } else {
            setRenewDialogOpen(true);
          }
        }}
        renewPlanOnSuccess
      />
      <RenewDialog
        open={renewDialogOpen}
        onClose={() => {
          setRenewDialogOpen(false);
        }}
      />
    </Box>
  );
}
