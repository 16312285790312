import { Box, Stack, Typography } from "@mui/material";
import { useClientMeetings } from "@trainwell/features";
import { isFuture } from "date-fns";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import { hideChat } from "src/lib/frontChat";
import LoadingPage from "../misc/LoadingPage";
import WithAuth from "../misc/WithAuth";
import CallCard from "./CallCard";

export default function CallsPage() {
  const client = useAppSelector((state) => state.client.client);
  const { data: calls } = useClientMeetings({
    userId: client?.user_id ?? "",
  });

  useEffect(() => {
    hideChat();
  });

  if (!client) {
    return <LoadingPage message="Loading client" />;
  }

  if (
    client.account.membership.state !== "active" &&
    client.account.membership.state !== "past_due"
  ) {
    return <Navigate to="/" />;
  }

  if (!calls) {
    return <LoadingPage message="Loading calls" />;
  }

  const upcomingCalls = calls?.filter((call) => isFuture(call.date_start));
  const pastCalls = calls?.filter((call) => !isFuture(call.date_start));

  return (
    <WithAuth>
      <Box
        sx={{
          py: 3,
          px: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 4 },
          maxWidth: "md",
        }}
      >
        <Typography variant="h1" sx={{ mb: 2 }}>
          Calls
        </Typography>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Upcoming
        </Typography>
        {upcomingCalls.length ? (
          <Stack spacing={2}>
            {upcomingCalls.map((call) => (
              <CallCard key={call.id} call={call} />
            ))}
          </Stack>
        ) : (
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            No upcoming calls
          </Typography>
        )}
        <Typography variant="h3" sx={{ mb: 2, mt: 4 }}>
          History
        </Typography>
        {pastCalls.length ? (
          <Stack spacing={2}>
            {pastCalls.map((call) => (
              <CallCard key={call.id} call={call} />
            ))}
          </Stack>
        ) : (
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
          >
            No call history
          </Typography>
        )}
      </Box>
    </WithAuth>
  );
}
