import * as Sentry from "@sentry/react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import BillingPage from "src/components/BillingPage/BillingPage";
import PaymentMethodValidationPage from "src/components/BillingPage/PaymentMethodValidationPage";
import CancelPage from "src/components/CalendarPage/CancelPage";
import ReschedulePage from "src/components/CalendarPage/ReschedulePage";
import SchedulePage from "src/components/CalendarPage/SchedulePage";
import CallsPage from "src/components/Calls/CallsPage";
import ChangeCoachPage from "src/components/ChangeCoachPage/ChangeCoachPage";
import Done from "src/components/ChangeCoachPage/Done";
import ScheduleCall from "src/components/ChangeCoachPage/ScheduleCall";
import FinishSignInPage from "src/components/LoginPage/FinishSignInPage";
import SignInPage from "src/components/LoginPage/SignInPage";
import VerifySession from "src/components/LoginPage/VerifySession";
import TippingPage from "src/components/TippingPage";
import UpgradeFreemiumPage from "src/components/UpgradeFreemium/UpgradeFreemiumPage";
import DownloadAppPage from "src/components/pages/DownloadAppPage";
import Layout from "src/components/pages/Layout";
import SurveyPage from "src/components/pages/SurveyPage";
import AppRoot from "./AppRoot";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "*",
    element: <AppRoot />,
    children: [
      {
        path: "sign-in",
        element: <SignInPage />,
      },
      {
        path: "finish-sign-in",
        element: <FinishSignInPage />,
      },
      {
        path: "survey",
        element: <SurveyPage />,
      },
      {
        path: "survey",
        element: <SurveyPage />,
      },
      {
        path: "download-app",
        element: <DownloadAppPage />,
      },
      {
        path: "verify-session/:sessionId/:path",
        element: <VerifySession />,
      },
      {
        path: "verify-session/:sessionId",
        element: <VerifySession />,
      },
      {
        path: "*",
        element: <Layout />,
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={"/billing" + location.search}
                state={{ searchQuery: window.location.search }}
              />
            ),
          },
          {
            path: "tip-coach",
            element: <TippingPage />,
          },
          {
            path: "change-coach",
            element: <ChangeCoachPage />,
          },
          {
            path: "schedule-call",
            element: <ScheduleCall />,
          },
          {
            path: "done",
            element: <Done />,
          },
          {
            path: "schedule",
            element: <SchedulePage />,
          },
          {
            path: "cancel/:eventId",
            element: <CancelPage />,
          },
          {
            path: "reschedule/:eventId",
            element: <ReschedulePage />,
          },
          {
            path: "upgrade-account",
            element: <UpgradeFreemiumPage />,
          },
          {
            path: "billing",
            element: <BillingPage />,
          },
          {
            path: "payment-method-validation",
            element: <PaymentMethodValidationPage />,
          },
          {
            path: "calls",
            element: <CallsPage />,
          },
        ],
      },
    ],
  },
]);

export function AppRoutes() {
  return <RouterProvider router={router} />;
}
