import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { isBlank } from "@trainwell/features";
import { fromUnixTime, isPast } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { hideChat, showChat } from "src/lib/frontChat";
import { trackEvent } from "src/slices/analyticsSlice";
import { cancelPlan, selectSubscriptionOptions } from "src/slices/billingSlice";
import DialogTitle from "../materialWrappers/DialogTitle";
import PauseDialog from "./PauseDialog";
import SubSwitchDialog from "./SubscriptionDialog";

const cancelOptions = [
  "Not seeing enough progress",
  "Not being held accountable",
  "Not getting enough guidance",
  "Not clicking with the trainer",
  "Not enjoying workouts",
  "Not happy with the tech",
  "Other",
];

type Props = {
  planId: string;
  open: boolean;
  onClose: () => void;
};

export default function CancelDialog({ planId, open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
  const plans = useAppSelector((state) => state.billing.plans);
  const activeTests = useAppSelector((state) => state.analytics.activeTests);
  const isPayToPauseC = activeTests.includes("pause_test_c");
  const client = useAppSelector((state) => state.client.client);
  const [confirmationChecks, setConfirmationChecks] = useState({
    loseDiscounts: false,
    noFreeTrial: false,
    noSameCoach: false,
    noCalls: false,
  });
  const [selectedCancelOptions, setSelectedCancelOptions] = useState<string[]>(
    [],
  );
  const [customCancelOption, setCustomCancelOption] = useState<string>("");
  const [switchOptionsDialogOpen, setSwitchOptionsDialogOpen] = useState(false);
  const subscriptionOptions = useAppSelector(selectSubscriptionOptions);
  const { enqueueSnackbar } = useSnackbar();

  const responseCancelOption = useMemo(
    () =>
      selectedCancelOptions.filter((o) => o !== "Other").length
        ? selectedCancelOptions
            .filter((o) => o !== "Other")
            [
              Math.floor(
                Math.random() *
                  selectedCancelOptions.filter((o) => o !== "Other").length,
              )
            ].toLowerCase()
        : "",
    [selectedCancelOptions.length],
  );

  const offerPlanSwitch =
    client?.account.plan.subscription_group_id ===
      "e12e32c3-475f-44d5-bdc7-66a993d04843" &&
    client?.account.membership.state !== "past_due" &&
    selectedCancelOptions.includes("Other");

  const allConfirmed = Object.values(confirmationChecks).every(
    (check) => check === true,
  );

  const plan = plans?.find((plan) => plan.id === planId);

  const allowPausingOption =
    !plan?.pausedUntil && client?.account.membership.state !== "past_due";

  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  useEffect(() => {
    if (activeStep === 3 && offerPlanSwitch) {
      dispatch(
        trackEvent({
          event_type: "button_shown_three_month_to_one_month",
        }),
      );
    }
  }, [activeStep, offerPlanSwitch, dispatch]);

  function handleClose() {
    onClose();

    setReason("");
    setSubmitting(false);
  }

  function handleCancelPlan() {
    setSubmitting(true);

    dispatch(
      cancelPlan({
        planId: planId,
        reason: reason,
        cancelOptions: selectedCancelOptions,
        responseCancelOption: responseCancelOption,
        customCancelOption: selectedCancelOptions.includes("Other")
          ? customCancelOption
          : undefined,
      }),
    )
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        Sentry.captureException(error);

        setSubmitting(false);

        enqueueSnackbar({
          message: "Something went wrong. Please try again.",
          variant: "error",
        });
      });
  }

  if (!plan) {
    return null;
  }

  const trialEndDate = plan.trialEnd ? fromUnixTime(plan.trialEnd) : undefined;
  const isDoneTrialing = trialEndDate ? isPast(trialEndDate) : true;

  const isPreTrial = Boolean(
    plan.trialEnd && !client?.account.dashboard.date_onboarded,
  );

  function handleNext() {
    if (activeStep === 0 && allConfirmed) {
      if (!(!isDoneTrialing && trialEndDate) && !isPayToPauseC) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  return (
    <>
      <Dialog
        open={open && !pauseDialogOpen}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          onClose={() => {
            handleClose();
          }}
        >
          Cancel plan
        </DialogTitle>
        {isPreTrial && (
          <Box
            sx={{
              background: "green",
              color: "white",
              textAlign: "center",
              padding: 1,
            }}
          >
            <Typography>
              <span style={{ fontWeight: "bold" }}>Remember:</span> Your free
              trial doesn&apos;t start until your first trainer call.
            </Typography>
          </Box>
        )}
        <DialogContent sx={{ pb: 0 }}>
          {activeStep === 0 && (
            <>
              <DialogContentText sx={{ fontWeight: "bold" }}>
                Please confirm that you understand the following:
              </DialogContentText>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.loseDiscounts}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          loseDiscounts: event.target.checked,
                        })
                      }
                    />
                  }
                  label="I will lose all discounts, credits, and coupons."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.noFreeTrial}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          noFreeTrial: event.target.checked,
                        })
                      }
                    />
                  }
                  label="I will not get another free trial."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.noSameCoach}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          noSameCoach: event.target.checked,
                        })
                      }
                    />
                  }
                  label="I may not be able to work with the same trainer if I return."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmationChecks.noCalls}
                      onChange={(event) =>
                        setConfirmationChecks({
                          ...confirmationChecks,
                          noCalls: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Any upcoming calls I have with my trainer will be cancelled."
                />
              </FormGroup>
            </>
          )}
          {activeStep === 1 && allowPausingOption && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                Would you rather pause instead?
              </DialogContentText>
              <DialogContentText>
                You can pause your plan for up to 1 billing period and hold onto
                your trainer and any discounts.
              </DialogContentText>
            </>
          )}
          {(activeStep === 2 || (activeStep === 1 && !allowPausingOption)) && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                We&apos;re sad to see you leave!
              </DialogContentText>
              <DialogContentText>
                Please let us know why you&apos;ll be leaving. Your trainer will
                not see this.
              </DialogContentText>
              <FormGroup>
                {cancelOptions.map((cancelOption, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={selectedCancelOptions.includes(cancelOption)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedCancelOptions((old) => [
                              ...old,
                              cancelOption,
                            ]);
                          } else {
                            setSelectedCancelOptions((old) =>
                              old.filter((o) => o !== cancelOption),
                            );
                          }
                        }}
                      />
                    }
                    label={cancelOption}
                  />
                ))}
              </FormGroup>
              {selectedCancelOptions.includes("Other") && (
                <TextField
                  autoFocus
                  label="Please specify"
                  fullWidth
                  multiline
                  variant="outlined"
                  value={customCancelOption}
                  onChange={(event) => {
                    setCustomCancelOption(event.target.value);
                  }}
                  sx={{ my: 2 }}
                />
              )}
            </>
          )}
          {(activeStep === 3 || (activeStep === 2 && !allowPausingOption)) && (
            <>
              <DialogContentText>
                We&apos;re sorry to hear that, but thanks for the honest
                feedback!
              </DialogContentText>
              {responseCancelOption && (
                <>
                  <DialogContentText sx={{ mt: 1 }}>
                    One last thing, could you give us some more detail about how
                    you were {responseCancelOption}?
                  </DialogContentText>
                  <TextField
                    autoFocus
                    placeholder={`I was ${responseCancelOption} because...`}
                    fullWidth
                    multiline
                    minRows={2}
                    variant="outlined"
                    value={reason}
                    onChange={(event) => {
                      setReason(event.target.value);
                    }}
                    helperText={reason.length > 450 ? "Max 450 characters" : ""}
                    error={reason.length > 450}
                    sx={{ my: 2 }}
                  />
                </>
              )}
              {offerPlanSwitch && (
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.1),
                    p: 1,
                    display: "flex",
                    my: 2,
                  }}
                >
                  <WarningRoundedIcon color="primary" sx={{ mr: 1 }} />
                  <Typography>
                    Wait, did you know you can switch to a monthly plan?
                  </Typography>
                </Card>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ mx: 4 }}>
          {activeStep === 1 && allowPausingOption && (
            <Button
              variant="text"
              onClick={() => {
                setPauseDialogOpen(true);
                dispatch(
                  trackEvent({
                    event_type: "pause_button_click",
                    event_content: { location: "cancel_dialog" },
                  }),
                );
              }}
            >
              Pause plan
            </Button>
          )}
          {activeStep === 3 && offerPlanSwitch && (
            <Button
              onClick={() => {
                setSwitchOptionsDialogOpen(true);

                dispatch(
                  trackEvent({
                    event_type: "button_pressed_three_month_to_one_month",
                  }),
                );
              }}
            >
              See monthly price
            </Button>
          )}
          {(activeStep === 3 || (activeStep === 2 && !allowPausingOption)) && (
            <LoadingButton
              variant="contained"
              onClick={() => {
                handleCancelPlan();
              }}
              disabled={Boolean(
                responseCancelOption &&
                  (isBlank(reason) || reason.length > 450),
              )}
              loading={submitting}
              color="error"
            >
              Cancel plan
            </LoadingButton>
          )}
          {activeStep < (!allowPausingOption ? 2 : 3) && (
            <Button
              variant="text"
              color="primary"
              onClick={handleNext}
              disabled={
                (activeStep === 0 && !allConfirmed) ||
                Boolean(
                  (activeStep === 2 ||
                    (activeStep === 1 && !allowPausingOption)) &&
                    selectedCancelOptions.length === 0,
                ) ||
                Boolean(
                  (activeStep === 2 ||
                    (activeStep === 1 && !allowPausingOption)) &&
                    selectedCancelOptions.includes("Other") &&
                    !customCancelOption,
                )
              }
            >
              {activeStep === 1 ? "Cancel plan" : "Next"}
            </Button>
          )}
        </DialogActions>
        <Stepper activeStep={activeStep} sx={{ my: 3 }} alternativeLabel>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          {allowPausingOption && (
            <Step>
              <StepLabel></StepLabel>
            </Step>
          )}
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
      </Dialog>
      <PauseDialog
        open={pauseDialogOpen}
        onClose={() => {
          setPauseDialogOpen(false);
          handleClose();
        }}
        planId={planId}
      />
      {offerPlanSwitch && (
        <SubSwitchDialog
          open={switchOptionsDialogOpen}
          subOptions={subscriptionOptions}
          onClose={() => {
            setSwitchOptionsDialogOpen(false);
            handleClose();
          }}
        />
      )}
    </>
  );
}
