import { Navigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";
import LoadingPage from "./LoadingPage";

type Props = {
  children: JSX.Element;
};

export default function WithAuth({ children }: Props) {
  const authType = useAppSelector((state) => state.auth.authType);

  if (authType === "weak") {
    return <Navigate to={"/sign-in"} />;
  }

  if (authType === "loading") {
    return <LoadingPage message="Loading auth" />;
  }

  return children;
}
