import { updateApiOptions } from "@trainwell/features";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";
import { app } from "src/lib/firebase";
import { handleAuthToken } from "src/slices/authSlice";
import { useAppDispatch, useAppSelector } from "./stateHooks";

const weakAuthRoutes = [
  "sign-in",
  "finish-sign-in",
  "change-coach",
  "schedule-call",
  "schedule",
  "cancel",
  "reschedule",
  "done",
  "survey",
  "download-app",
  "verify-session",
];

export function useAuth() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId: foundSessionId } = useParams();
  const sessionId = useAppSelector((state) => state.auth.authSessionId);
  const authType = useAppSelector((state) => state.auth.authType);
  const [didForceLogout, setDidForceLogout] = useState(false);

  useEffect(() => {
    console.log("Firebase app: ", app.name);

    if (authType === "session") {
      return;
    }

    const auth = getAuth();

    const unsubscribeAuthChange = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        console.log("Auth: sign in with uid ", uid);

        user.getIdTokenResult().then((idTokenResult) => {
          updateApiOptions({
            prefixUrl: TRAINWELL_API_DOMAIN,
            headers: {
              Authorization: `Bearer ${idTokenResult.token}`,
              "api-key": TRAINWELL_API_KEY,
            },
            hooks: {
              beforeRequest: [
                async (request) => {
                  const sessionId =
                    typeof sessionStorage === "undefined"
                      ? null
                      : sessionStorage.getItem("authSessionId");

                  if (sessionId) {
                    request.headers.set("auth-session-id", sessionId);
                  }
                },
              ],
            },
          });

          dispatch(handleAuthToken(idTokenResult))
            .unwrap()
            .then(() => {
              if (location.pathname.includes("login")) {
                console.log("Auth: Redirecting to home page");
                navigate("/" + location.search);
              }
            })
            .catch(() => {
              console.log("Auth: No userId found");

              logout();
            });
        });
      } else {
        console.log("Auth: sign out");

        if (!weakAuthRoutes.some((path) => location.pathname.includes(path))) {
          console.log("Auth: redirect to sign in");
          navigate("/sign-in", { replace: true });
        } else {
          console.log("Auth: not redirecting to sign in");
        }
      }
    });

    return () => {
      unsubscribeAuthChange();
    };
  }, []);

  useEffect(() => {
    if (
      authType === "weak" &&
      !didForceLogout &&
      !weakAuthRoutes.some((path) => location.pathname.includes(path))
    ) {
      setDidForceLogout(true);
      logout();
    }
  }, [location]);

  function logout() {
    navigate("/sign-in");

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Sign out: success");
      })
      .catch(() => {
        console.log("Sign out: fail");
      });

    localStorage.clear();
    sessionStorage.clear();
  }
}
