import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CreditScoreRoundedIcon from "@mui/icons-material/CreditScoreRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { endOfMonth, isBefore, parse } from "date-fns";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  removePaymentMethod,
  setDefaultPaymentMethod,
} from "src/slices/billingSlice";
import PaymentDialog from "./PaymentDialog";

const brands: Record<string, { name: string; iconSrc: string }> = {
  visa: {
    name: "Visa",
    iconSrc: "/assets/cards/visa.png",
  },
  amex: {
    name: "American Express",
    iconSrc: "/assets/cards/american_express.png",
  },
  discover: {
    name: "Discover",
    iconSrc: "/assets/cards/discover.png",
  },
  mastercard: {
    name: "Mastercard",
    iconSrc: "/assets/cards/mastercard.png",
  },
};

type Props = {
  sx?: SxProps<Theme>;
};

export default function PaymentMethods({ sx = [] }: Props) {
  const dispatch = useAppDispatch();
  const paymentMethods = useAppSelector(
    (state) => state.billing.paymentMethods,
  );
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const endOfMonthDate = endOfMonth(new Date());

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Payment Methods
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {paymentMethods?.map((paymentMethod, i) => {
          const expiresDate = paymentMethod.exp_month
            ? parse(
                `${paymentMethod.exp_month.toString().padStart(2, "0")}/${
                  paymentMethod.exp_year
                }`,
                "MM/yyyy",
                new Date(),
              )
            : undefined;

          const isExpired = expiresDate
            ? isBefore(expiresDate, endOfMonthDate)
            : false;

          return (
            <Grid item xs={12} sm={6} key={i}>
              <Card variant="outlined" sx={{ p: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{ alignItems: "center" }}
                    >
                      {paymentMethod.type === "link" ? (
                        <img
                          src={"/assets/cards/link.svg"}
                          alt={"Link"}
                          height={32}
                          width={32}
                        />
                      ) : (
                        paymentMethod.brand! in brands && (
                          <img
                            src={brands[paymentMethod.brand!].iconSrc}
                            alt={brands[paymentMethod.brand!].name}
                            height={32}
                            width={32}
                          />
                        )
                      )}
                      {paymentMethod.type !== "link" && (
                        <Typography sx={{ fontWeight: "bold" }}>
                          {brands[paymentMethod.brand!]?.name ??
                            "Payment Method"}
                        </Typography>
                      )}
                    </Stack>
                    {paymentMethod.is_default && (
                      <Chip size="small" label="Default" />
                    )}
                  </Box>
                </Box>
                {paymentMethod.type === "link" ? (
                  <Typography
                    variant="body2"
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    Link email: {paymentMethod.link_email}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    •••• •••• •••• {paymentMethod.last4}
                  </Typography>
                )}
                {paymentMethod.type === "link" && (
                  <Button
                    size="small"
                    variant="text"
                    href="https://app.link.com/login"
                    target="_blank"
                  >
                    Manage your Link payment method
                  </Button>
                )}
                {paymentMethod.type !== "link" && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      display: "flex",
                      alignItems: "self-end",
                    }}
                  >
                    {isExpired && <WarningRoundedIcon sx={{ mr: 1 }} />}{" "}
                    {isExpired ? "Expired" : "Expires"}{" "}
                    {paymentMethod.exp_month!.toString().padStart(2, "0")}/
                    {paymentMethod.exp_year}
                  </Typography>
                )}
                {paymentMethods.length === 1 && !paymentMethod.is_default && (
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems="center"
                    sx={{ mt: 1 }}
                  >
                    <LoadingButton
                      variant="text"
                      onClick={() => {
                        setButtonLoading(true);
                        dispatch(
                          setDefaultPaymentMethod(paymentMethod.id),
                        ).then(() => {
                          setButtonLoading(false);
                        });
                      }}
                      loading={buttonLoading}
                      size="small"
                      startIcon={<CreditScoreRoundedIcon />}
                    >
                      Make default
                    </LoadingButton>
                  </Stack>
                )}
                {paymentMethods.length > 1 && (
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems="center"
                    sx={{ mt: 1 }}
                  >
                    {!paymentMethod.is_default && (
                      <LoadingButton
                        variant="text"
                        onClick={() => {
                          setButtonLoading(true);

                          dispatch(
                            setDefaultPaymentMethod(paymentMethod.id),
                          ).then(() => {
                            setButtonLoading(false);
                          });
                        }}
                        loading={buttonLoading}
                        size="small"
                        startIcon={<CreditScoreRoundedIcon />}
                      >
                        Make default
                      </LoadingButton>
                    )}
                    {!paymentMethod.is_default && (
                      <LoadingButton
                        variant="text"
                        color="error"
                        onClick={() => {
                          setButtonLoading(true);
                          dispatch(removePaymentMethod(paymentMethod.id)).then(
                            () => {
                              setButtonLoading(false);
                            },
                          );
                        }}
                        loading={buttonLoading}
                        size="small"
                        startIcon={<DeleteRoundedIcon />}
                      >
                        Delete
                      </LoadingButton>
                    )}
                  </Stack>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Button
        variant="text"
        startIcon={<AddRoundedIcon />}
        onClick={() => {
          setPaymentDialogOpen(true);
        }}
      >
        Add payment method
      </Button>
      <PaymentDialog
        open={paymentDialogOpen}
        onClose={() => {
          setPaymentDialogOpen(false);
        }}
      />
    </Box>
  );
}
