import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { Footer } from "@trainwell/ui";
import { getAuth, signOut } from "firebase/auth";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/stateHooks";

const drawerWidth = 240;

export default function Layout() {
  const navigate = useNavigate();
  const client = useAppSelector((state) => state.client.client);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function logout() {
    navigate("/sign-in");

    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Auth: sign out success");

        localStorage.clear();
        sessionStorage.clear();
      })
      .catch((error) => {
        console.error("Auth: sign out failed");
        Sentry.captureException(error);
      });
  }

  const isFree =
    client?.account.plan.price === 0 && client.account.plan.id !== "freemium";
  const isFreemium = client?.account.plan.id === "freemium";

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Box>
        <Toolbar sx={{ px: "8px !important" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              width={30}
              height={30}
              src={client?.headshot_url || "/assets/profile.png"}
              alt="profile picture"
              style={{
                borderRadius: "15px",
              }}
            />
            <Box sx={{ ml: 1 }}>
              <Typography sx={{ fontWeight: "bold", lineHeight: 1 }}>
                {client?.full_name}
              </Typography>
              {client?.account.membership.date_membership_started && (
                <Typography
                  variant="body2"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  {client.email}
                </Typography>
              )}
            </Box>
          </Box>
        </Toolbar>
        <Divider />
        <List>
          <ListItem
            name="Billing"
            path="/billing"
            index
            icon={<CreditCardRoundedIcon />}
            disabled={isFree || isFreemium}
          />
          <ListItem
            name="Book call"
            path="/schedule"
            icon={<CalendarMonthRoundedIcon />}
            disabled={
              (client?.account.membership.state !== "active" &&
                client?.account.membership.state !== "past_due") ||
              isFreemium
            }
          />

          <ListItem
            name="Tip trainer"
            path="/tip-coach"
            icon={<MonetizationOnRoundedIcon />}
            disabled={
              (client?.account.membership.state !== "active" &&
                client?.account.membership.state !== "past_due") ||
              isFreemium ||
              isFree
            }
          />
          <ListItem
            name="Switch trainer"
            path="/change-coach"
            icon={<SwapHorizRoundedIcon />}
            disabled={
              isFreemium || client?.disable_manual_coach_switching === true
            }
          />
          <ListItem
            name="Manage calls"
            path="/calls"
            icon={<VideocamRoundedIcon />}
            disabled={isFreemium}
          />
        </List>
      </Box>
      <Box>
        <Divider />
        <Box sx={{ px: 2, py: 1 }}>
          <Button
            variant="text"
            startIcon={<LogoutRoundedIcon />}
            onClick={logout}
            fullWidth
            size="small"
            color="error"
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", maxWidth: "100vw" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: (theme) => theme.palette.background.paper,
          borderBottom: 1,
          borderColor: "divider",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", sm: "flex-start" },
          }}
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuRoundedIcon />
          </IconButton>
          <img
            src="/assets/trainwell-wordmark.svg"
            alt="logo"
            width={112}
            height={30}
          />
          <IconButton disabled sx={{ ml: 2, opacity: 0 }}>
            <MenuRoundedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          width: { md: `calc(100% - ${drawerWidth}px)` },
          maxWidth: "100vw",
        }}
      >
        <Box
          sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Toolbar />
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

type Props = {
  name: string;
  path: string;
  icon: JSX.Element;
  index?: boolean;
  disabled?: boolean;
};

function ListItem({ name, path, icon, index, disabled }: Props) {
  const selected =
    location.pathname.includes(path) || (index && location.pathname === "/");

  return (
    <ListItemButton
      href={path}
      selected={selected}
      disabled={disabled}
      sx={{
        mx: 1,
        borderRadius: 1,
      }}
    >
      <ListItemIcon
        sx={{
          color: (theme) => (selected ? theme.palette.primary.main : undefined),
          minWidth: "48px",
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          sx: {
            fontWeight: selected ? "bold" : undefined,
            color: (theme) =>
              selected ? theme.palette.primary.main : undefined,
          },
        }}
      />
    </ListItemButton>
  );
}
