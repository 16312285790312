import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPage from "src/components/misc/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { handleAuthSession } from "src/slices/authSlice";

export default function VerifySession() {
  const { sessionId, path } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authSessionStatus = useAppSelector(
    (state) => state.auth.authSessionStatus,
  );

  useEffect(() => {
    if (sessionId) {
      dispatch(handleAuthSession(sessionId))
        .unwrap()
        .then(() => {
          if (path) {
            console.log(path);
            navigate("/" + (path as string));
          } else {
            navigate("/");
          }
        });
    }
  }, [dispatch, navigate, sessionId]);

  if (authSessionStatus === "failed") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Typography>The link you used is invalid</Typography>
      </Box>
    );
  }

  return <LoadingPage message="Hold on while we verify your link" />;
}
